import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged, map, pluck } from 'rxjs/operators'

@Component({
  selector: 'cradua-search-staff',
  templateUrl: './search-staff.component.html',
  styleUrls: ['./search-staff.component.scss']
})
export class SearchStaffComponent implements OnInit {

  @Output() searchEmit = new EventEmitter<any>()
  @Output() backEmit = new EventEmitter<any>()
  @ViewChild('search', { static: true }) searchForm: ElementRef
  searchSubscription$: Subscription

  constructor() { }

  ngOnInit(): void {
  }


  ngAfterViewInit(): void {
    this.searchSubscription$ = fromEvent(this.searchForm.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        pluck('target', 'value'),
        map((value) => value),
      )
      .subscribe((data) => this.searchEmit.emit(data))
  }


}
