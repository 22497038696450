import { createAction, props } from '@ngrx/store'
import { IsNumber } from 'class-validator'

/**
 * Load Generic Collection Actions
 */

export function loadGateway(actionType: string) {
  // console.log("Reaching Load Action ", actionType);
  const newLoadAction = createAction(`[${actionType}] Load ${actionType} Load`, props<{ modeltype: string }>())
  return newLoadAction
}

export function loadGatewaySuccess(actionType: string) {
  const loadSuccess = createAction(`[${actionType}] Load ${actionType} Data Success`, props<{ returnedData: any[] }>())
  return loadSuccess
}

export function loadGatewayFailure(actionType: string) {
  const loadFailure = createAction(`[${actionType}] Load ${actionType} Data Failure`, props<{ error: any }>())
  return loadFailure
}

export function loadByIdGateway(actionType: string) {
  return createAction(`[${actionType}] Load ${actionType} Data`, props<{ modeltype: string; id: string }>())
}

export function loadByIdGatewaySuccess(actionType: string) {
  return createAction(`[${actionType}] Load ${actionType} Data Success`, props<{ returnedData: any[] }>())
}

export function loadByIdGatewayFailure(actionType: string) {
  return createAction(`[${actionType}] Load ${actionType} Data Failure`, props<{ error: any }>())
}

export function addGateway(actionType: string) {
  return createAction(`[${actionType}] Add ${actionType} Data`, props<{ dataToAdd: any[]; modelType: string }>())
}

export function addGatewaySuccess(actionType: string) {
  return createAction(`[${actionType}] Add ${actionType} Data Success`, props<{ returnedData: any[] }>())
}

export function addGatewayFailure(actionType: string) {
  return createAction(`[${actionType}] Add ${actionType} Data Failure`, props<{ error: any }>())
}

export function removeGateway(actionType: string) {
  return createAction(`[${actionType}] Remove ${actionType} Data`, props<{ ids: string[]; modelType: string }>())
}

export function removeGatewaySuccess(actionType: string) {
  return createAction(`[${actionType}] Remove ${actionType} Data Success`, props<{ ids: string[] }>())
}

export function removeGatewayFailure(actionType: string) {
  return createAction(`[${actionType}] Remove ${actionType} Data Failure`, props<{ error: any }>())
}

export function updateGateway(actionType: string) {
  return createAction(
    `[${actionType}] Update ${actionType} Data`,
    props<{ dataToAdd: any[]; deletedData: any[]; modelType: string }>(),
  )
}

export function updateGatewaySuccess(actionType: string) {
  return createAction(`[${actionType}] Update ${actionType} Data Success`, props<{ returnedData: any[] }>())
}

export function updateGatewayFailure(actionType: string) {
  return createAction(`[${actionType}] Update ${actionType} Data Failure`, props<{ error: any }>())
}

export function genericFilterGateway(actionType: string) {
  return createAction(
    `[${actionType}] Load ${actionType} Data`,
    props<{ modeltype: string; entryType: string; searchField: string }>(),
  )
}

export function genericFilterGatewaySuccess(actionType: string) {
  return createAction(`[${actionType}] Load ${actionType} Data Success`, props<{ returnedData: any[] }>())
}

export function genericFilterGatewayFailure(actionType: string) {
  return createAction(`[${actionType}] Load ${actionType} Data Failure`, props<{ error: any }>())
}

export function getStudentGateway(actionType: string) {
  return createAction(
    `[${actionType}] Load Student ${actionType} Data`,
    props<{ modeltype: string; data: any; }>(),
  )
}

export function getStudentGatewaySuccess(actionType: string) {
  return createAction(`[${actionType}] Load Student ${actionType} Data Success`, props<{ returnedData: any[] }>())
}

export function getStudentGatewayFailure(actionType: string) {
  return createAction(`[${actionType}] Load Student ${actionType} Data Failure`, props<{ error: any }>())
}

export function addStudentGateway(actionType: string) {
  return createAction(
    `[${actionType}] Add Student ${actionType} Data`,
    props<{ modeltype: string; data: any }>(),
  )
}

export function addStudentGatewaySuccess(actionType: string) {
  return createAction(`[${actionType}] Add Student ${actionType} Data Success`, props<{ returnedData: any[] }>())
}

export function addStudentGatewayFailure(actionType: string) {
  return createAction(`[${actionType}] Add Student ${actionType} Data Failure`, props<{ error: any }>())
}


export function updateStudentGateway(actionType: string) {
  return createAction(
    `[${actionType}] Update Student ${actionType} Data`,
    props<{ modeltype: string; data: any[] }>(),
  )
}

export function updateStudentGatewaySuccess(actionType: string) {
  return createAction(`[${actionType}] Update Student ${actionType} Data Success`, props<{ returnedData: any[] }>())
}

export function updateStudentGatewayFailure(actionType: string) {
  return createAction(`[${actionType}] Update Student ${actionType} Data Failure`, props<{ error: any }>())
}

export function addStaffPermissionGateway(actionType: string) {
  return createAction(
    `[${actionType}] Add Staff Permission ${actionType} Data`,
    props<{ modeltype: string; data: any[] }>(),
  )
}

export function addStaffPermissionGatewaySuccess(actionType: string) {
  return createAction(`[${actionType}] Add Staff Permission ${actionType} Data Success`, props<{ returnedData: any[] }>())
}

export function addStaffPermissionGatewayFailure(actionType: string) {
  return createAction(`[${actionType}] Add Staff Permission ${actionType} Data Failure`, props<{ error: any }>())
}

export function loadStaffPermissionGateway(actionType: string) {
  return createAction(
    `[${actionType}] Load Staff Permission ${actionType} Data`,
    props<{ modeltype: string; data: any[] }>(),
  )
}

export function loadStaffPermissionGatewaySuccess(actionType: string) {
  return createAction(`[${actionType}] Load Staff Permission ${actionType} Data Success`, props<{ returnedData: any[] }>())
}

export function loadStaffPermissionGatewayFailure(actionType: string) {
  return createAction(`[${actionType}] Load Staff Permission ${actionType} Data Failure`, props<{ error: any }>())
}



export const loadGatewayClearState = (actionType: string) =>
createAction(
  `[${actionType}] CLear ${actionType} GateWay  CLear State`,
  props<{ modelType: any }>(),
);

export const loadGatewayClearStateSuccess = (actionType: string) =>
createAction(
  `[${actionType}] CLear ${actionType} GateWay  CLear State Success`,
  props<{ returnedData: any[] }>(),
);
