import { Component, OnInit } from '@angular/core'
import { Credentials } from '@cradua/data'
import { GatewayFacade } from '@cradua/gateway-front'
import { Observable } from 'rxjs';
import { AuthFacade } from '../../+state/auth.facade'

@Component({
  selector: 'cradua-auth-container',
  templateUrl: './auth-container.component.html',
  styleUrls: ['./auth-container.component.scss'],
})
export class AuthContainerComponent implements OnInit {
  isLoadingLogin$: Observable<any>;
  getAuthError$: Observable<any>;
  constructor(private authFacade: AuthFacade) {}

  ngOnInit(): void {
    this.getAuthError$ = this.authFacade.getAuthError$;
    this.isLoadingLogin$ = this.authFacade.loaded$;
  }



  onLogin(credentials: Credentials) {
 this.authFacade.login(credentials);
  }
}
