<div
  class="col-md-12"
  *ngIf="
    isLoadingStaff === false &&
    (isLoadingCertificatePermission === false ||
      isLoadingCertificatePermission === null ||
      isLoadingIdCardPermission === false ||
      isLoadingIdCardPermission === null)
  "
>
  <form [formGroup]="permissionForm">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Full Name</th>
          <th scope="col">Email</th>
          <th scope="col">Phone</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody formArrayName="Permissions">
        <tr *ngFor="let staff of permissionFormArray['controls']; index as i" [formGroupName]="i">
          <th scope="row">{{ 1 + i }}</th>
          <td>{{ staff?.value.StaffFullName }}</td>
          <td>{{ staff?.value.Email_Address }}</td>
          <td>{{ staff?.value.Phone_No }}</td>
          <td>
            <div class="form-check" *ngIf="url === undefined">
              <input class="form-check-input" formControlName="UploadCertificate" type="checkbox" />
              <label class="form-check-label" for="flexCheckDefault"> Upload Certificate </label>
            </div>

            <div class="form-check" *ngIf="url === undefined">
              <input class="form-check-input" formControlName="PrintCertificate" type="checkbox" />
              <label class="form-check-label" for="flexCheckDefault"> Print Certificate </label>
            </div>

            <div class="form-check" *ngIf="url === undefined">
              <input class="form-check-input" formControlName="ApproveCertificate" type="checkbox" />
              <label class="form-check-label" for="flexCheckDefault"> Approve Certificate </label>
            </div>
            <div class="form-check" *ngIf="url === undefined">
              <input class="form-check-input" formControlName="DeleteCertificate" type="checkbox" />
              <label class="form-check-label" for="flexCheckDefault"> Approve Certificate </label>
            </div>

            <div class="form-check" *ngIf="url === 'IDCard'">
              <input class="form-check-input" formControlName="PrintIdCard" type="checkbox" />
              <label class="form-check-label" for="flexCheckDefault"> Print ID Card </label>
            </div>
            <div class="form-check" *ngIf="url === 'IDCard'">
              <input class="form-check-input" formControlName="CardExpiration" type="checkbox" />
              <label class="form-check-label" for="flexCheckDefault"> Card Expiration </label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="display: flex; justify-items: center; justify-content: center">
      <button type="submit" (click)="onSubmit(permissionForm.value)" class="btn btn-secondary btn-md">Submit</button>
    </div>
  </form>
</div>

<cradua-list-spinner
  *ngIf="isLoadingStaff === true || isLoadingCertificatePermission === true || isLoadingIdCardPermission === true"
>
</cradua-list-spinner>
