import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { GatewayActions, GatewayStoreSelectors, GatewayStoreReducer } from './index'

@Injectable()
export class GatewayFacade {
  constructor(private store: Store<GatewayStoreReducer.GatewayPartialState>) {}

  gatewayRecord$ = this.store.select(GatewayStoreSelectors.getSelected)
  gatewayError$ = this.store.select(GatewayStoreSelectors.getGatewayError)

  getselected() {
    return this.store.select(GatewayStoreSelectors.getSelected)
  }

  isLoadingGateway(modeltype: string) {
    return this.store.select(GatewayStoreSelectors.loadingGateway(modeltype))
  }

  genericFilterGateway(modeltype: string, entryType: string, searchField: string) {
    this.store.dispatch(GatewayActions.genericFilterGateway(modeltype)({ modeltype, entryType, searchField }))
    return this.store.select(GatewayStoreSelectors.genericFilterGateway(modeltype))
  }
  getStudentInfoGateway(modeltype: string, data: any) {
    this.store.dispatch(GatewayActions.getStudentGateway(modeltype)({ modeltype, data }))
    return this.store.select(GatewayStoreSelectors.loadingGateway(modeltype))
  }
  getCertificateStudentGateway(modeltype: string, data: any) {
    this.store.dispatch(GatewayActions.getStudentGateway(modeltype)({ modeltype, data }))
    return this.store.select(GatewayStoreSelectors.loadingGateway(modeltype))
  }

  addCertificateStudentGateway(modeltype: string, data: any) {
    this.store.dispatch(GatewayActions.addStudentGateway(modeltype)({ modeltype, data }))
    return this.store.select(GatewayStoreSelectors.loadingGateway(modeltype))
  }

  updateCertificateStudentGateway(modeltype: string, data: any[]) {
    this.store.dispatch(GatewayActions.updateStudentGateway(modeltype)({ modeltype, data }))
    return this.store.select(GatewayStoreSelectors.loadingGateway(modeltype))
  }

  addStaffPermissionGateway(modeltype: string, data: any[]) {
    this.store.dispatch(GatewayActions.addStaffPermissionGateway(modeltype)({ modeltype, data }))
    return this.store.select(GatewayStoreSelectors.loadingGateway(modeltype))
  }

  loadStaffPermissionGateway(modeltype: string, data: any[]) {
    this.store.dispatch(GatewayActions.loadStaffPermissionGateway(modeltype)({ modeltype, data }))
    return this.store.select(GatewayStoreSelectors.loadingGateway(modeltype))
  }

  loadGatewayClear(modelType: string) {
    this.store.dispatch(GatewayActions.loadGatewayClearState(modelType)({ modelType }))
    return this.store.select(GatewayStoreSelectors.getAllGateway(modelType))
  }

  getAllGateway(modeltype: string) {
    return this.store.select(GatewayStoreSelectors.getAllGateway(modeltype))
  }

  loadGateway(modeltype: string) {
    this.store.dispatch(GatewayActions.loadGateway(modeltype)({ modeltype }))
    return this.store.select(GatewayStoreSelectors.getAllGateway(modeltype))
  }

  add(modelType: string, dataToAdd: any[]) {
    this.store.dispatch(GatewayActions.addGateway(modelType)({ dataToAdd, modelType }))
  }

  update(modelType: string, dataToAdd: any[], deletedData: any[]) {
    this.store.dispatch(GatewayActions.updateGateway(modelType)({ dataToAdd, deletedData, modelType }))
  }

  remove(modelType: string, ids: string[]) {
    this.store.dispatch(GatewayActions.removeGateway(modelType)({ ids, modelType }))
  }
}
