<nav class="top_nav">
  <div class="logo">
    <i class="icofont-navigation-menu icofont-2x" [routerLink]="['/dashboard']"></i>

    <img src="assets/images/cradua.png"  alt="" class="app_logo" />
  </div>

  <img src="assets/images/pexels-anna-shvets-3727459.jpg" [matMenuTriggerFor]="menu" alt="" class="user_img" />
</nav>

<mat-menu #menu="matMenu">


  <button mat-menu-item  style="border:none; outline:none;">
    <mat-icon>logout</mat-icon>
    <span (click)="logOut()">Log Out</span>
  </button>


</mat-menu>
