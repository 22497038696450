import { Injectable } from '@angular/core'
import { createEffect, Actions, ofType } from '@ngrx/effects'

import { map, exhaustMap, catchError, tap } from 'rxjs/operators'
import * as AuthActions from './auth.actions'
import { AuthService } from '../service/auth.service'
import { of } from 'rxjs'
import { Credentials } from '@cradua/data'
import { Router } from '@angular/router'
import { NotificationService } from "@cradua/notification";

@Injectable()
export class AuthEffects {

login$ = createEffect(() =>
this.actions$.pipe(
  ofType(AuthActions.login),
  map((action) => action.credentials),
  exhaustMap((auth: Credentials) =>
    this.authService.login(auth).pipe(
      map((auth) => {

        if (localStorage.getItem('LoggedInUser')) {
          localStorage.removeItem('LoggedInUser');
          localStorage.setItem('LoggedInUser', JSON.stringify(auth['user']));

          this.authService.doLoginUser(auth['user'].username, { jwt: auth.token, refreshToken: '' });
        } else {
          localStorage.setItem('LoggedInUser', JSON.stringify(auth['user']));
          this.authService.doLoginUser(auth['user'].username, { jwt: auth.token, refreshToken: '' });
        }
        this.authService.isLoading$.next(false);
        return AuthActions.loginSuccess({ data: auth['user'] });
      }),
      catchError((error) => {

        this.notification.error('Invalid Username or password')
        return of(AuthActions.loginFailure({ error: error.error.message }))
      })
    )
  )
)
);


loadUsers$ = createEffect(() =>
this.actions$.pipe(
  ofType(AuthActions.init),
  map((action) => action.query),
  exhaustMap((query: string) =>
    this.authService.getUsers(query).pipe(
      map((users) => {
        return AuthActions.loadAuthSuccess({ users });
      }),
      catchError((error) => of(AuthActions.loadAuthFailure({ error: error.error.message })))
    )
  )
)
);


loginSuccess$ = createEffect(
  () => this.actions$.pipe(ofType(AuthActions.loginSuccess), tap(() => {
    return this.router.navigate(['/dashboard'])
  })),
  { dispatch: false }
);


loginRedirect$ = createEffect(
  () =>
    this.actions$.pipe(
      ofType(AuthActions.loginRedirect, AuthActions.logout),
      tap((authed) => {
        localStorage.removeItem('LoggedInUser');
        localStorage.removeItem('Role');
        localStorage.removeItem('REFRESH_TOKEN');
        localStorage.removeItem('JWT_TOKEN');
        localStorage.removeItem('totalCount');
        this.authService.isLoggedIn$.next(false);
        this.authService.isLoggedIn$.next(false)
        this.router.navigate([ '/auth/login' ]);
      })
    ),
  { dispatch: false }
);



  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private notification: NotificationService
    ) {}
}
