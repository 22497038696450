import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router'

import { Store, select } from '@ngrx/store'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'any',
})
export class AuthorizedGuard implements CanActivate {
  isAuthorized = false
  loggedInUser = JSON.parse(localStorage.getItem('LoggedInUser'))

  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.loggedInUser && state.url === '/auth/staff') {
      if (this.loggedInUser?.RoleType !== 'Certificate' && this.loggedInUser?.RoleType !== 'Passport') {
        this.router.navigate(['/dashboard'])
        return false
      } else {
        return true
      }
    }
  }
}
