import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { Route, RouterModule } from '@angular/router'
import { GatewayFacade } from '@cradua/gateway-front'
import { MaterialModule } from '@cradua/material'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { AuthEffects } from './+state/auth.effects'
import { AuthFacade } from './+state/auth.facade'
import * as fromAuth from './+state/auth.reducer'
import { AuthComponent } from './components/auth/auth.component'
import { StaffComponent } from './components/staff/staff.component'
import { AuthContainerComponent } from './containers/auth-container/auth-container.component'
import { StaffContainerComponent } from './containers/staff-container/staff-container.component'
import { SpinnersModule } from '@cradua/spinners';
import { SearchStaffComponent } from './components/search-staff/search-staff.component';
import { StaffListComponent } from './components/staff-list/staff-list.component'
import { AuthorizedGuard } from './auth-permission.guard'

export const authRoutes: Route[] = [
  { path: 'login', component: AuthContainerComponent, data: { title: 'Login'} },
  { path: 'staff', component: StaffContainerComponent, canActivate: [AuthorizedGuard] },

];


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    SpinnersModule,
    RouterModule.forChild(authRoutes),
    StoreModule.forFeature(fromAuth.AUTH_FEATURE_KEY, fromAuth.reducer),
    EffectsModule.forFeature([AuthEffects]),
  ],
  declarations: [AuthContainerComponent, AuthComponent, StaffContainerComponent, StaffComponent, SearchStaffComponent, StaffListComponent],
  providers: [
    AuthFacade,
    GatewayFacade,
    AuthorizedGuard
  ],
})
export class AuthFrontModule {}
