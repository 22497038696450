import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from './service/auth.service';
import {Router} from '@angular/router';
import { AuthFacade } from '@cradua/auth-front';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,
    private myRoute: Router, public authFacade: AuthFacade,){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      // console.log("Logged Serv up", this.authService.isLoggedIn());
      if(this.authService.isLoggedIn()){


        this.authFacade.getLoginUser;
        this.authService.getUser();


        return true;
      }else{
        this.myRoute.navigate(["auth/login"]);
        return false;
      }
  }

}
