<table class="table table-striped">
  <thead>
    <tr >
      <th scope="col">#</th>
      <th scope="col">Full Name</th>
      <th scope="col">Email</th>
      <th scope="col">Phone</th>
      <th scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let staff of staffs; index as i" (click)="onViewStudent(staff)"
        style="cursor: pointer;">

      <th scope="row">{{1 + i}}</th>
      <td>{{staff?.StaffFullName}}</td>
      <td>{{staff?.Email_Address}}</td>
      <td>{{staff?.Phone_No}}</td>

    </tr>

  </tbody>

</table>
