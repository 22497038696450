import { environment } from 'apps/cradua/src/environments/environment'
import { HttpClient, HttpParams, HttpHeaders, HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http'

import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IsString } from 'class-validator'

@Injectable({
  providedIn: 'root',
})
export class GatewayService {
  url = environment.apiUrl
  constructor(private http: HttpClient) {}

  getAll(type: string): Observable<any[]> {
    return this.http.get<any[]>(this.url + 'gateway?type=' + type)
  }

  createData(type: string, data: any[]): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    }

    return this.http.post<any[]>(this.url + 'gateway/createdata?type=' + type, data)
  }
  getSingleData(type: string, id: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    }

    return this.http.get(this.url + 'gateway?type=' + type + id, httpOptions)
  }
  getStudentInfo(type: string, data: any): Observable<any> {
    return this.http.post(this.url + 'student-api/studentinfo', data)
  }
  getStudent(type: string, data: any): Observable<any> {
    return this.http.post(this.url + 'student-api/student', data)
  }

  addStudent(type: string, data: any): Observable<any> {
    return this.http.post(this.url + 'student-api', data)
  }

  updateStudent(type: string, data: any): Observable<any> {
    return this.http.post(this.url + 'student-api/update-student', data)
  }
  addStaffCertificatePermission(type: string, data: any): Observable<any> {
    return this.http.post(this.url + 'student-api/add-permission', data)
  }
  loadStaffCertificatePermission(type: string, id: any): Observable<any> {
    return this.http.post(this.url + 'student-api/load-permission', { id })
  }
  addStaffIdCardPermission(type: string, data: any): Observable<any> {
    return this.http.post(this.url + 'student-api/add-idpermission', data)
  }
  loadStaffIdCardPermission(type: string, id: any): Observable<any> {
    return this.http.post(this.url + 'student-api/load-idpermission', { id })
  }
  updateData(type: string, data: any[], deletedData: any[]): Observable<any[]> {
    console.log('Reaching Update function ', type)
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    }

    let newDataStruct = { data: data, deletedData: deletedData }

    return this.http.post<any[]>(this.url + 'gateway/update?type=' + type, newDataStruct)
  }

  deleteData(type: string, ids: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: ids,
    }

    let params = new HttpParams()
    params = params.append('stakeholderRef', ids.join(', '))

    return this.http.post(this.url + 'gateway/delete?type=' + type, httpOptions)
  }

  public uploadFile(file: File, data: any[], type: string): Observable<HttpEvent<{}>> {
    const formData = new FormData()
    formData.append('file', file, file.name)
    formData.append('Body', JSON.stringify(data))

    const options = {
      reportProgress: true,
    }

    const req = new HttpRequest('POST', `${this.url + 'gateway/createdocument?type=' + type}`, formData, options)
    return this.http.request(req)
  }

  genericFilter(type: string, entryType: string, searchField: any): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    }

    const newDataStruct = { entryType: entryType, searchField: searchField }

    return this.http.post<any[]>(this.url + 'gateway/search?type=' + type, newDataStruct)
  }
}
