import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Credentials } from '@cradua/data';
import { NotificationService } from '@cradua/notification';

@Component({
  selector: 'cradua-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {

  loginForm: FormGroup;
  @Input() isLoadingLogin: boolean
  @Input() getAuthError: string
  @Output() emitLogin: EventEmitter<any> = new EventEmitter<any>()

  constructor(private fb: FormBuilder, private notifyService: NotificationService) {}

  ngOnInit(): void {
    this.login();
  }

  login() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  onSubmit(formValue: Credentials) {
    if(this.loginForm.invalid) {
      this.notifyService.warn('Please all fields are required')
      return
    }
    this.emitLogin.emit(formValue);
  }
}
