import { NgModule } from '@angular/core'
import { Routes, RouterModule, PreloadAllModules } from '@angular/router'
import { AuthGuard } from 'libs/auth-front/src/lib/auth.guard'
import { AppComponent } from './app.component'

const routes: Routes = [
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  { path: 'auth', loadChildren: () => import('@cradua/auth-front').then((m) => m.AuthFrontModule) },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('@cradua/dashboard').then((m) => m.DashboardModule),
  },
  {
    path: 'certificate',
    canActivate: [AuthGuard],
    loadChildren: () => import('@cradua/graduationcertificate').then((m) => m.GraduationcertificateModule),
  },
  {
    path: 'passport',
    canActivate: [AuthGuard],
    loadChildren: () => import('@cradua/passport').then((m) => m.PassportModule),
  },

  // { path: '**', component: HomeComponent, pathMatch: 'full' },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabled',
    }),
  ],

  exports: [RouterModule],
})
export class AppRoutingModule {}
