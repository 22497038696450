import { Injectable } from '@angular/core'
import { Credentials } from '@cradua/data'

import { select, Store } from '@ngrx/store'

import * as AuthActions from './auth.actions'
import * as AuthSelectors from './auth.selectors'
import { AuthStoreReducer } from './index'

@Injectable()
export class AuthFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(AuthSelectors.getAuthLoaded))
  allAuth$ = this.store.pipe(select(AuthSelectors.getAllAuth))
  selectedAuth$ = this.store.pipe(select(AuthSelectors.getSelected))
  getAuthError$ = this.store.pipe(select(AuthSelectors.getAuthError))

  constructor(private store: Store<AuthStoreReducer.AuthPartialState>) {}

  login(credentials: Credentials) {
    this.store.dispatch(AuthActions.login({credentials}))
  }

  searchStaff(query: string) {
    this.store.dispatch(AuthActions.init({query}))
  }

  logout(){

		this.store.dispatch(AuthActions.logout());
	}

  get getLoginUser() {

   return null;
 }
}
