import { Component, OnInit } from '@angular/core'
import { AuthFacade } from '@cradua/auth-front'
@Component({
  selector: 'cradua-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor(private authFacade: AuthFacade) { }

  ngOnInit(): void {
  }

  logOut() {
this.authFacade.logout()
  }

}
