import { createReducer, on, Action, ActionReducerMap } from '@ngrx/store'
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'

import * as GatewayActions from './gateway.actions'

export const Gateway_FEATURE_KEY = 'gatewaygeneric'
export const Gateway_FEATURE_KEY_Continent = 'gatewaygenericcontinent'
export const Gateway_FEATURE_KEY_Country = 'gatewaygenericcountry'
export const Gateway_FEATURE_KEY_State = 'gatewaygenericstate'
export const Gateway_FEATURE_KEY_Lga = 'gatewaygenericlga'
export const Gateway_FEATURE_KEY_Community = 'gatewaygenericcommunity'
export const Gateway_FEATURE_KEY_Currency = 'gatewaygenericcurrency'
export const Gateway_FEATURE_KEY_Sex = 'gatewaygenericsex'
export const Gateway_FEATURE_KEY_Bank = 'gatewaygenericbank'
export const Gateway_FEATURE_KEY_IdentityUser = 'gatewaygenericidentityuser'
export const Gateway_FEATURE_KEY_UserStaffDetail = 'gatewaygenericidentityuserstaffdetail'
export const Gateway_FEATURE_KEY_Faculty = 'gatewaygenericidentityfaculty'
export const Gateway_FEATURE_KEY_Department = 'gatewaygenericidentitydepartment'
export const Gateway_FEATURE_KEY_GraduationCertificate = 'gatewaygenericidentitygraduationcertificate'
export const Gateway_FEATURE_KEY_CertificatePermission = 'gatewaygenericidentityCertificatePermission'
export const Gateway_FEATURE_KEY_CertificateSignature = 'gatewaygenericidentitycertificatesignature'
export const Gateway_FEATURE_KEY_PostGraduateDegree = 'postGraduateDegree'
export const Gateway_FEATURE_KEY_College = 'college'
export const Gateway_FEATURE_KEY_Session = 'session'
export const Gateway_FEATURE_KEY_StudentInfo = 'gatewaygenericidentitystudentinfo'
export const Gateway_FEATURE_KEY_CardExpiration = 'gatewaygenericidentitycardexpiration'
export const Gateway_FEATURE_KEY_IdCardPermissionn = 'gatewaygenericidentityIdCardPermission'
export function Gateway_FeatureKey(key: string) {
  return key
}

export enum UploadStatus {
  Ready = 'Ready',
  Requested = 'Requested',
  Started = 'Started',
  Failed = 'Failed',
  Completed = 'Completed',
}

export interface GatewayState extends EntityState<any> {
  selectedId?: string | number // which Model Type record has been selected
  loaded: boolean // has the Model list been loaded
  error?: string | null // last none error (if any)
  status: UploadStatus
  progress: number | null
}

export interface GatewayPartialState {
  readonly [Gateway_FEATURE_KEY]: GatewayState
  readonly [Gateway_FEATURE_KEY_Continent]: GatewayState
  readonly [Gateway_FEATURE_KEY_Country]: GatewayState
  readonly [Gateway_FEATURE_KEY_State]: GatewayState
  readonly [Gateway_FEATURE_KEY_Lga]: GatewayState
  readonly [Gateway_FEATURE_KEY_Community]: GatewayState
  readonly [Gateway_FEATURE_KEY_Currency]: GatewayState
  readonly [Gateway_FEATURE_KEY_Sex]: GatewayState
  readonly [Gateway_FEATURE_KEY_Bank]: GatewayState
  readonly [Gateway_FEATURE_KEY_IdentityUser]: GatewayState
  readonly [Gateway_FEATURE_KEY_UserStaffDetail]: GatewayState
  readonly [Gateway_FEATURE_KEY_Faculty]: GatewayState
  readonly [Gateway_FEATURE_KEY_Department]: GatewayState
  readonly [Gateway_FEATURE_KEY_GraduationCertificate]: GatewayState
  readonly [Gateway_FEATURE_KEY_CertificatePermission]: GatewayState
  readonly [Gateway_FEATURE_KEY_CertificateSignature]: GatewayState
  readonly [Gateway_FEATURE_KEY_PostGraduateDegree]: GatewayState
  readonly [Gateway_FEATURE_KEY_College]: GatewayState
  readonly [Gateway_FEATURE_KEY_Session]: GatewayState
  readonly [Gateway_FEATURE_KEY_StudentInfo]: GatewayState
  readonly [Gateway_FEATURE_KEY_CardExpiration]: GatewayState
  readonly [Gateway_FEATURE_KEY_IdCardPermissionn]: GatewayState
}

export const GatewayAdapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (gateway) => gateway.id,
  sortComparer: false,
})

export const initialState: GatewayState = GatewayAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  error: null,
  selectedId: null,
  status: UploadStatus.Ready,
  progress: null,
})

export const gatewayReducer = (type: string) => {
  return createReducer(
    initialState,
    on(GatewayActions.loadGateway(type), (state) => ({
      ...state,
      loaded: true,
      error: null,
    })),
    on(GatewayActions.loadGatewaySuccess(type), (state, { returnedData }) =>
      GatewayAdapter.setAll(returnedData, { ...state, loaded: false }),
    ),

    on(GatewayActions.loadGatewayFailure(type), (state, { error }) => ({
      ...state,
      loaded: false,
      error,
    })),

    on(GatewayActions.addGateway(type), (state) => ({
      ...state,
      loaded: true,
      error: null,
    })),
    on(GatewayActions.addGatewaySuccess(type), (state, { returnedData }) =>
      GatewayAdapter.addMany(returnedData, { ...state, loaded: false }),
    ),
    on(GatewayActions.addGatewayFailure(type), (state, { error }) => ({
      ...state,
      loaded: false,
      error,
    })),

    on(GatewayActions.updateGateway(type), (state) => ({
      ...state,
      loaded: true,
      error: null,
    })),
    on(GatewayActions.updateGatewaySuccess(type), (state, { returnedData }) =>
      GatewayAdapter.upsertMany(returnedData, { ...state, loaded: false }),
    ),
    on(GatewayActions.updateGatewayFailure(type), (state, { error }) => ({
      ...state,
      loaded: false,
      error,
    })),

    on(GatewayActions.genericFilterGateway(type), (state) => ({
      ...state,
      loaded: true,
      error: null,
    })),
    on(GatewayActions.genericFilterGatewaySuccess(type), (state, { returnedData }) =>
      GatewayAdapter.setAll(returnedData, { ...state, loaded: false }),
    ),

    on(GatewayActions.genericFilterGatewayFailure(type), (state, { error }) => ({
      ...state,
      loaded: false,
      error,
    })),

    on(GatewayActions.getStudentGateway(type), (state) => ({
      ...state,
      loaded: true,
      error: null,
    })),
    on(GatewayActions.getStudentGatewaySuccess(type), (state, { returnedData }) =>
      GatewayAdapter.setAll(returnedData, { ...state, loaded: false }),
    ),

    on(GatewayActions.getStudentGatewayFailure(type), (state, { error }) => ({
      ...state,
      loaded: false,
      error,
    })),

    on(GatewayActions.addStudentGateway(type), (state) => ({
      ...state,
      loaded: true,
      error: null,
    })),
    on(GatewayActions.addStudentGatewaySuccess(type), (state, { returnedData }) =>
      GatewayAdapter.addMany(returnedData, { ...state, loaded: false }),
    ),

    on(GatewayActions.addStudentGatewayFailure(type), (state, { error }) => ({
      ...state,
      loaded: false,
      error,
    })),

    on(GatewayActions.updateStudentGateway(type), (state) => ({
      ...state,
      loaded: true,
      error: null,
    })),
    on(GatewayActions.updateStudentGatewaySuccess(type), (state, { returnedData }) =>
      GatewayAdapter.upsertMany(returnedData, { ...state, loaded: false }),
    ),

    on(GatewayActions.updateStudentGatewayFailure(type), (state, { error }) => ({
      ...state,
      loaded: false,
      error,
    })),

    on(GatewayActions.loadStaffPermissionGateway(type), (state) => ({
      ...state,
      loaded: true,
      error: null,
    })),
    on(GatewayActions.loadStaffPermissionGatewaySuccess(type), (state, { returnedData }) =>
      GatewayAdapter.setAll(returnedData, { ...state, loaded: false }),
    ),

    on(GatewayActions.loadStaffPermissionGatewayFailure(type), (state, { error }) => ({
      ...state,
      loaded: false,
      error,
    })),

    on(GatewayActions.addStaffPermissionGateway(type), (state) => ({
      ...state,
      loaded: true,
      error: null,
    })),
    on(GatewayActions.addStaffPermissionGatewaySuccess(type), (state, { returnedData }) =>
      GatewayAdapter.setAll(returnedData, { ...state, loaded: false }),
    ),

    on(GatewayActions.addStaffPermissionGatewayFailure(type), (state, { error }) => ({
      ...state,
      loaded: false,
      error,
    })),

    on(GatewayActions.removeGateway(type), (state, inputtype) => ({
      ...state,
      loaded: true,
      error: null,
    })),
    on(GatewayActions.removeGatewaySuccess(type), (state, { ids }) =>
      GatewayAdapter.removeMany(ids, { ...state, loaded: false }),
    ),
    on(GatewayActions.removeGatewayFailure(type), (state, { error }) => ({
      ...state,
      loaded: false,
      error,
    })),

    on(GatewayActions.loadGatewayClearState(type), (state) => ({
      ...state,
      loaded: true,
      error: null,
    })),
    on(GatewayActions.loadGatewayClearStateSuccess(type), (state, { returnedData }) =>
      GatewayAdapter.setAll(returnedData, { ...state, loaded: false }),
    ),
  )
}

export function reducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Country')(state, action)
}

export function bankreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Bank')(state, action)
}

export function continentreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Continent')(state, action)
}
export function countryreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Country')(state, action)
}
export function statereducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('State')(state, action)
}
export function lgareducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Lga')(state, action)
}
export function communityreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Communities')(state, action)
}
export function sexreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Sex')(state, action)
}
export function identityUserreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('IdentityUser')(state, action)
}
export function userStaffDetailreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('UserStaffDetail')(state, action)
}
export function facultyreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Faculty')(state, action)
}
export function departmentreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Department')(state, action)
}
export function graduationcertificatereducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('GraduationCertificate')(state, action)
}
export function certificatePermission(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('CertificatePermission')(state, action)
}
export function certificatesignaturereducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('CertificateSignature')(state, action)
}
export function postGraduateDegree(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('PostGraduateDegree')(state, action)
}
export function college(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('College')(state, action)
}
export function session(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('Session')(state, action)
}
export function studentinforeducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('StudentInfo')(state, action)
}
export function cardexpirationreducer(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('CardExpiration')(state, action)
}
export function idcardPermission(state: GatewayState | undefined, action: Action) {
  return gatewayReducer('IdCardPermission')(state, action)
}
