import { createAction, props } from '@ngrx/store'
import { Credentials, UserRO } from '@cradua/data';

export const init = createAction('[Auth Page] Init', props<{query: string}>())

export const loadAuthSuccess = createAction('[Auth/API] Load Auth Success', props<{ users: UserRO[] }>())

export const loadAuthFailure = createAction('[Auth/API] Load Auth Failure', props<{ error: any }>())



export const login = createAction('[Login Page] Login', props<{ credentials: Credentials }>());
export const loginSuccess = createAction('[Login Page] Login Success', props<{ data: UserRO }>());
export const loginFailure = createAction('[Login Page] Login Failure', props<{ error: any }>());


export const loginRedirect = createAction('[Auth/API] Login Redirect');


export const logout = createAction('[Auth] Logout');
export const logoutConfirmation = createAction('[Auth] Logout Confirmation');
export const logoutConfirmationDismiss = createAction(
  '[Auth] Logout Confirmation Dismiss'
);
