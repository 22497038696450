import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserRO } from '@cradua/data';

@Component({
  selector: 'cradua-staff-list',
  templateUrl: './staff-list.component.html',
  styleUrls: ['./staff-list.component.scss']
})
export class StaffListComponent implements OnInit {

  @Input() staffs: UserRO[]
  @Input() isLoadingStaff: boolean

  @Output() viewEmit = new EventEmitter<any>()

  constructor() { }

  ngOnInit(): void {
  }

  onViewStudent(value) {
    this.viewEmit.emit(value)
  }

}
