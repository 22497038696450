import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { CertificatePermissionDto, IDCardPermissionDto, UserRO } from '@cradua/data'
import { GatewayFacade } from '@cradua/gateway-front'
import { Observable } from 'rxjs'
import { AuthFacade } from '../../+state/auth.facade'
import { Location } from '@angular/common'

@Component({
  selector: 'cradua-staff-container',
  templateUrl: './staff-container.component.html',
  styleUrls: ['./staff-container.component.scss'],
})
export class StaffContainerComponent implements OnInit {
  isLoadingStaff$: Observable<boolean>
  isLoadingCertificatePermission$: Observable<boolean>
  staff$: Observable<UserRO[]>
  certificatePermission$: Observable<CertificatePermissionDto[]>
  isLoadingIdCardPermission$: Observable<boolean>
  idcardPermission$: Observable<IDCardPermissionDto[]>
  task = 'list'
  selectedStaff: UserRO[]
  url: string

  constructor(
    private authFacade: AuthFacade,
    private gatewayFacade: GatewayFacade,
    private router: Router,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.url = this.location.getState()['value']

    this.isLoadingStaff$ = this.authFacade.loaded$
    this.staff$ = this.authFacade.allAuth$
    this.certificatePermission$ = this.gatewayFacade.loadGateway('CertificatePermission')
    this.idcardPermission$ = this.gatewayFacade.loadGateway('IdCardPermission')
    console.log('readingdffsd...', this.url)
  }

  onSearch(query: string) {
    this.task = 'list'
    this.authFacade.searchStaff(query)
  }

  onBack() {
    if (this.url === undefined) {
      this.router.navigate(['/certificate'])
    } else {
      this.router.navigate(['/passport'])
    }
  }

  // onViewStaff(value) {
  //   this.task = 'assign';
  //   // console.log('form value ', value)
  //   this.selectedStaff = [value];
  //   this.gatewayFacade.loadStaffPermissionGateway('CertificatePermission', value.id)
  // }
  onViewStaff(value) {
    this.task = 'assign'
    // console.log('form value ', value)
    this.selectedStaff = [value]
    if (this.url === undefined) {
      this.gatewayFacade.loadStaffPermissionGateway('CertificatePermission', value.id)
    } else {
      this.gatewayFacade.loadStaffPermissionGateway('IdCardPermission', value.id)
    }
  }
  // onAssignPermission(value) {
  //   this.gatewayFacade.addStaffPermissionGateway('CertificatePermission', value)
  //   this.isLoadingCertificatePermission$ = this.gatewayFacade.isLoadingGateway('CertificatePermission');
  // }
  onAssignPermission(value) {
    if (this.url === undefined) {
      this.gatewayFacade.addStaffPermissionGateway('CertificatePermission', value)
      this.isLoadingCertificatePermission$ = this.gatewayFacade.isLoadingGateway('CertificatePermission')
    } else {
      this.gatewayFacade.addStaffPermissionGateway('IdCardPermission', value)
      this.isLoadingIdCardPermission$ = this.gatewayFacade.isLoadingGateway('IdCardPermission')
    }
  }
}
