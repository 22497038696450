import { createReducer, on, Action } from '@ngrx/store'
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'

import * as AuthActions from './auth.actions'
import { UserRO } from '@cradua/data'

export const AUTH_FEATURE_KEY = 'auth'

export interface State extends EntityState<UserRO> {
  selectedUserId?: string | number // which Auth record has been selected
  loaded: boolean // has the Auth list been loaded
  error?: string | null // last known error (if any)
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: State
}

export const authAdapter: EntityAdapter<UserRO> = createEntityAdapter<UserRO>({
  selectId: (record) => record.id,
})

export const initialState: State = authAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  error: null,
  selectedUserId: null
})

const authReducer = createReducer(
  initialState,
  on(AuthActions.init, (state) => ({ ...state, loaded: true, error: null })),
  on(AuthActions.loadAuthSuccess, (state, { users }) => authAdapter.setAll(users, { ...state, loaded: false })),
  on(AuthActions.loadAuthFailure, (state, { error }) => ({ ...state,loaded: false, error })),

  on(AuthActions.login, (state) => ({ ...state, loaded: true, error: null })),
  on(AuthActions.loginSuccess, (state, { data }) => authAdapter.setOne(data, { ...state, loaded: false })),
  on(AuthActions.loginFailure, (state, { error }) => ({ ...state,loaded: false, error })),


  on(AuthActions.logout, (state) => ({ ...state, loaded: false, error: null })),
  on(AuthActions.logoutConfirmation, (state) => authAdapter.setOne(null, { ...state, loaded: false })),


)

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action)
}

