import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store'
import {
  Gateway_FEATURE_KEY,
  Gateway_FEATURE_KEY_Continent,
  Gateway_FEATURE_KEY_Country,
  Gateway_FEATURE_KEY_State,
  Gateway_FEATURE_KEY_Lga,
  Gateway_FEATURE_KEY_Community,
  Gateway_FEATURE_KEY_IdentityUser,
  Gateway_FEATURE_KEY_Bank,
  Gateway_FEATURE_KEY_Currency,
  Gateway_FEATURE_KEY_Sex,
  Gateway_FEATURE_KEY_UserStaffDetail,
  Gateway_FEATURE_KEY_Department,
  Gateway_FEATURE_KEY_Faculty,
  Gateway_FEATURE_KEY_GraduationCertificate,
  Gateway_FEATURE_KEY_CertificatePermission,
  Gateway_FEATURE_KEY_CertificateSignature,
  Gateway_FEATURE_KEY_PostGraduateDegree,
  Gateway_FEATURE_KEY_College,
  Gateway_FEATURE_KEY_Session,
  Gateway_FEATURE_KEY_StudentInfo,
  Gateway_FEATURE_KEY_CardExpiration,
  Gateway_FEATURE_KEY_IdCardPermissionn,
  GatewayState,
  GatewayPartialState,
  GatewayAdapter,
  UploadStatus,
} from './gateway.reducer'

// Lookup the 'Stakeholder Reference' feature state managed by NgRx
export const getGatewayState = createFeatureSelector<GatewayPartialState, GatewayState>(Gateway_FEATURE_KEY)

export const getGatewayStateBank = createFeatureSelector<GatewayPartialState, GatewayState>(Gateway_FEATURE_KEY_Bank)

export const getGatewayStateContinent = createFeatureSelector<GatewayPartialState, GatewayState>(
  Gateway_FEATURE_KEY_Continent,
)

export const getGatewayStateCountry = createFeatureSelector<GatewayPartialState, GatewayState>(
  Gateway_FEATURE_KEY_Country,
)

export const getGatewayStateState = createFeatureSelector<GatewayPartialState, GatewayState>(Gateway_FEATURE_KEY_State)

export const getGatewayStateLga = createFeatureSelector<GatewayPartialState, GatewayState>(Gateway_FEATURE_KEY_Lga)

export const getGatewayStateCommunity = createFeatureSelector<GatewayPartialState, GatewayState>(
  Gateway_FEATURE_KEY_Community,
)

export const getGatewayStateCurrency = createFeatureSelector<GatewayPartialState, GatewayState>(
  Gateway_FEATURE_KEY_Currency,
)

export const getGatewayStateIdentityUser = createFeatureSelector<GatewayPartialState, GatewayState>(
  Gateway_FEATURE_KEY_IdentityUser,
)

export const getGatewayStateSex = createFeatureSelector<GatewayPartialState, GatewayState>(Gateway_FEATURE_KEY_Sex)

export const getGatewayStateUserStaffDetail = createFeatureSelector<GatewayPartialState, GatewayState>(
  Gateway_FEATURE_KEY_UserStaffDetail,
)
export const getGatewayStateFaculty = createFeatureSelector<GatewayPartialState, GatewayState>(
  Gateway_FEATURE_KEY_Faculty,
)
export const getGatewayStateDepartment = createFeatureSelector<GatewayPartialState, GatewayState>(
  Gateway_FEATURE_KEY_Department,
)
export const getGatewayStateGraduationCertificate = createFeatureSelector<GatewayPartialState, GatewayState>(
  Gateway_FEATURE_KEY_GraduationCertificate,
)
export const getGatewayStateStudentInfo = createFeatureSelector<GatewayPartialState, GatewayState>(
  Gateway_FEATURE_KEY_StudentInfo,
)
export const getGatewayStateCertificatePermission = createFeatureSelector<GatewayPartialState, GatewayState>(
  Gateway_FEATURE_KEY_CertificatePermission,
)
export const getGatewayStateCertificateSignature = createFeatureSelector<GatewayPartialState, GatewayState>(
  Gateway_FEATURE_KEY_CertificateSignature,
)
export const getGatewayStatePostGraduateDegree = createFeatureSelector<GatewayPartialState, GatewayState>(
  Gateway_FEATURE_KEY_PostGraduateDegree,
)
export const getGatewayStateCollege = createFeatureSelector<GatewayPartialState, GatewayState>(
  Gateway_FEATURE_KEY_College,
)
export const getGatewayStateSession = createFeatureSelector<GatewayPartialState, GatewayState>(
  Gateway_FEATURE_KEY_Session,
)
export const getGatewayStateCardExpiration = createFeatureSelector<GatewayPartialState, GatewayState>(
  Gateway_FEATURE_KEY_CardExpiration,
)
export const getGatewayStateIdCardPermission = createFeatureSelector<GatewayPartialState, GatewayState>(
  Gateway_FEATURE_KEY_IdCardPermissionn,
)
const { selectAll, selectEntities } = GatewayAdapter.getSelectors()

export function switchStateSlice(type): any {
  if (type === 'Bank') {
    return getGatewayStateBank
  }

  if (type === 'Continent') {
    return getGatewayStateContinent
  }

  if (type === 'Country') {
    return getGatewayStateCountry
  }

  if (type === 'State') {
    return getGatewayStateState
  }

  if (type === 'Lga') {
    return getGatewayStateLga
  }
  if (type === 'Communities') {
    return getGatewayStateCommunity
  }

  if (type === 'IdentityUser') {
    return getGatewayStateIdentityUser
  }
  if (type === 'UserStaffDetail') {
    return getGatewayStateUserStaffDetail
  }
  if (type === 'Faculty') {
    return getGatewayStateFaculty
  }
  if (type === 'Department') {
    return getGatewayStateDepartment
  }
  if (type === 'GraduationCertificate') {
    return getGatewayStateGraduationCertificate
  }
  if (type === 'StudentInfo') {
    return getGatewayStateStudentInfo
  }
  if (type === 'CertificatePermission') {
    return getGatewayStateCertificatePermission
  }
  if (type === 'CertificateSignature') {
    return getGatewayStateCertificateSignature
  }
  if (type === 'PostGraduateDegree') {
    return getGatewayStatePostGraduateDegree
  }
  if (type === 'College') {
    return getGatewayStateCollege
  }
  if (type === 'Session') {
    return getGatewayStateSession
  }
  if (type === 'CardExpiration') {
    return getGatewayStateCardExpiration
  }
  if (type === 'IdCardPermission') {
    return getGatewayStateIdCardPermission
  }
}

export const getAllGateway = (type) => {
  return createSelector(switchStateSlice(type), (state: GatewayState) => selectAll(state))
}

export const getGatewayError = createSelector(getGatewayState, (state: GatewayState) => state.error)

export const loadingGateway = (type) => {
  return createSelector(switchStateSlice(type), (state: GatewayState) => state.loaded)
}

export const genericFilterGateway = (type) => {
  return createSelector(switchStateSlice(type), (state: GatewayState) => selectAll(state))
}

export const getGatewayEntities = createSelector(getGatewayState, (state: GatewayState) => selectEntities(state))

export const getSelectedId = createSelector(getGatewayState, (state: GatewayState) => state.selectedId)

export const getSelected = createSelector(
  getGatewayEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId],
)

const getError = (state: GatewayState): string => state.error

const getStarted = (state: GatewayState): boolean => state.status === UploadStatus.Started

const getRequested = (state: GatewayState): boolean => state.status === UploadStatus.Requested

const getReady = (state: GatewayState): boolean => state.status === UploadStatus.Ready

const getProgress = (state: GatewayState): number => state.progress

const getInProgress = (state: GatewayState): boolean => state.status === UploadStatus.Started && state.progress >= 0

const getFailed = (state: GatewayState): boolean => state.status === UploadStatus.Failed

const getCompleted = (state: GatewayState): boolean => state.status === UploadStatus.Completed

export const selectUploadFileError: MemoizedSelector<object, string> = createSelector(getGatewayState, getError)

export const selectUploadFileReady: MemoizedSelector<object, boolean> = createSelector(getGatewayState, getReady)

export const selectUploadFileRequested: MemoizedSelector<object, boolean> = createSelector(
  getGatewayState,
  getRequested,
)

export const selectUploadFileStarted: MemoizedSelector<object, boolean> = createSelector(getGatewayState, getStarted)

export const selectUploadFileProgress: MemoizedSelector<object, number> = createSelector(getGatewayState, getProgress)

export const selectUploadFileInProgress: MemoizedSelector<object, boolean> = createSelector(
  getGatewayState,
  getInProgress,
)

export const selectUploadFileFailed: MemoizedSelector<object, boolean> = createSelector(getGatewayState, getFailed)

export const selectUploadFileCompleted: MemoizedSelector<object, boolean> = createSelector(
  getGatewayState,
  getCompleted,
)
