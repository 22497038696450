import { Credentials, UserRO } from '@cradua/data';
import { environment } from 'apps/cradua/src/environments/environment'
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';


import { catchError, mapTo, tap } from 'rxjs/operators';
import { config } from '../models/config';
import { Tokens } from '../models/token';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private readonly JWT_TOKEN = 'JWT_TOKEN';
	private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
	private loggedUser: string;

	url = environment.apiUrl;
	accountURL = environment.apiUrl;
	isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @Output() loggedInUser: EventEmitter<UserRO> = new EventEmitter<UserRO>();
	@Output() isAuthenticated: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private http: HttpClient) {}

	getAllUsersBySubscriberId(): Observable<UserRO[]> {
		return this.http.get<UserRO[]>(this.url + 'user/subscriber/', {});
	}


	login(data: Credentials): Observable<UserRO> {
		/**
     * Simulate a failed login to display the error
     * message for the login form.
     */
		// if (username !== 'test' && username !== 'ngrx') {
		//   return throwError('Invalid username or password');
		// }

		// return of({ id: '1233', username: 'User', phoneNo: '363633'  });

		//console.log(' Data From Fomr ', data);

		return this.http.post<UserRO>(this.accountURL + 'auth/login', data);
	}

	getUsers(query: string): Observable<UserRO[]> {

		return this.http.post<UserRO[]>(this.accountURL + 'auth/search', {query});
	}


	logout() {
		return this.http
			.post<any>(`${config.apiUrl}/logout`, {
				refreshToken: this.getRefreshToken()
			})
			.pipe(
				tap(() => this.doLogoutUser()),
				mapTo(true),
				catchError((error) => {
					alert(error.error);
					return of(false);
				})
			);
	}


	getUser(): UserRO {

		let user: any = JSON.parse(localStorage.getItem('LoggedInUser'));


		if (user) {
      this.isLoggedIn$.next(true)
		this.loggedInUser.emit(user.user);
			this.isAuthenticated.emit(true);
			return user.user;
		} else {
			this.isAuthenticated.emit(false);
		}
		return user;
	}

	isLoggedIn() {
		return !!this.getJwtToken();
	}

	refreshToken() {
		return this.http
			.post<any>(`${config.apiUrl}/refresh`, {
				refreshToken: this.getRefreshToken()
			})
			.pipe(
				tap((tokens: Tokens) => {
					this.storeJwtToken(tokens.jwt);
				})
			);
	}

	getJwtToken() {
		return localStorage.getItem(this.JWT_TOKEN);
	}

	public doLoginUser(username: string, tokens: Tokens) {
		this.loggedUser = username;
		this.storeTokens(tokens);

	}

	private doLogoutUser() {
		this.loggedUser = null;
		this.removeTokens();
	}

	private getRefreshToken() {
		return localStorage.getItem(this.REFRESH_TOKEN);
	}

	private storeJwtToken(jwt: string) {
		localStorage.setItem(this.JWT_TOKEN, jwt);
	}

	private storeTokens(tokens: Tokens) {
		localStorage.setItem(this.JWT_TOKEN, tokens.jwt);
		localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
	}

	private removeTokens() {
		localStorage.removeItem(this.JWT_TOKEN);
		localStorage.removeItem(this.REFRESH_TOKEN);
	}
}
