import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router'
import { MenuComponent } from './components/menu/menu.component'
import { MaterialModule } from '@cradua/material'
import { AuthFacade } from '@cradua/auth-front'
@NgModule({
  imports: [CommonModule, RouterModule, MaterialModule],
  declarations: [MenuComponent],
  exports: [MenuComponent],
  providers: [AuthFacade]
})
export class DashboardNavModule {}
