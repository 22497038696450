import { Component, ElementRef, OnChanges, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FormArray, FormBuilder, FormGroup } from '@angular/forms'
import { CertificatePermissionDto, IDCardPermissionDto, UserRO } from '@cradua/data'

@Component({
  selector: 'cradua-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss'],
})
export class StaffComponent implements OnChanges {
  @Input() staffs: UserRO[]
  @Input() certificatePermission: CertificatePermissionDto[]
  @Input() url: string
  @Input() isLoadingStaff: boolean
  @Input() isLoadingCertificatePermission: boolean = false
  @Input() isLoadingIdCardPermission: boolean = false
  @Input() idcardPermission: IDCardPermissionDto[]

  @Output() saveEmit = new EventEmitter<any>()

  permissionForm: FormGroup

  constructor(private fb: FormBuilder) {}

  ngOnChanges(): void {
    this.createPermissionForm(this.url)
    console.log('reading...', this.certificatePermission, this.idcardPermission)
  }

  createPermissionForm(url: string) {
    this.permissionForm = this.fb.group({
      Permissions: this.fb.array([]),
    })

    if (url != null) {
      console.log(this.idcardPermission)
      this.setIDCardPermissionForm(this.staffs, this.idcardPermission)
    } else {
      this.setPermissionForm(this.staffs, this.certificatePermission)
    }
  }

  get permissionFormArray() {
    return this.permissionForm.get('Permissions') as FormArray
  }
  setIDCardPermissionForm(staffs: any[], idcardPermission: IDCardPermissionDto[]) {
    console.log(idcardPermission)
    staffs.forEach((staff) => {
      this.permissionFormArray.push(
        this.fb.group({
          StaffFullName: staff.StaffFullName,
          Email_Address: staff.Email_Address,
          Phone_No: staff.Phone_No,
          StaffId: staff.id,
          // DateCreated: this.myDate.toDateString(),
          PrintIdCard: [idcardPermission[0]?.PrintIdCard],
          CardExpiration: [idcardPermission[0]?.CardExpiration],

          id: idcardPermission?.length ? idcardPermission[0]?.id : [undefined],
        }),
      )
    })
  }
  setPermissionForm(staffs: any[], certificatePermission: CertificatePermissionDto[]) {
    staffs.forEach((staff) => {
      this.permissionFormArray.push(
        this.fb.group({
          StaffFullName: staff.StaffFullName,
          Email_Address: staff.Email_Address,
          Phone_No: staff.Phone_No,
          StaffId: staff.id,
          PrintCertificate: [certificatePermission[0]?.PrintCertificate],
          UploadCertificate: [certificatePermission[0]?.UploadCertificate],
          ApproveCertificate: [certificatePermission[0]?.ApproveCertificate],
          DeleteCertificate: [certificatePermission[0]?.DeleteCertificate],
          id: certificatePermission?.length ? certificatePermission[0]?.id : [undefined],
        }),
      )
    })
  }
  onSubmit(formValue) {
    // console.log('assignment', this.url, formValue)
    if (this.url === 'IDCard') {
      const mapFormValue = (formValue['Permissions'] as IDCardPermissionDto[])
        .filter((permission) => permission.PrintIdCard || permission.CardExpiration || permission.id)
        .map((cert) => (cert.id != null ? cert : { ...cert, id: undefined }))

      this.saveEmit.emit(mapFormValue)
    } else {
      const mapFormValue = (formValue['Permissions'] as CertificatePermissionDto[])
        .filter(
          (permission) =>
            permission.ApproveCertificate ||
            permission.PrintCertificate ||
            permission.UploadCertificate ||
            permission.DeleteCertificate ||
            permission.id,
        )
        .map((cert) => (cert.id != null ? cert : { ...cert, id: undefined }))

      this.saveEmit.emit(mapFormValue)
    }
  }

  //   onSubmit(formValue) {
  //  const mapFormValue = (formValue['Permissions'] as CertificatePermissionDto[])
  //                     .filter(permission => permission.ApproveCertificate ||
  //                     permission.PrintCertificate ||
  //                     permission.UploadCertificate ||
  //                     permission.DeleteCertificate ||
  //                     permission.id )
  //                     .map(cert => cert.id != null ? cert : {...cert, id: undefined})

  //     this.saveEmit.emit(mapFormValue)
  //   }
}
