<div class="container">
  <div class="row">
    <cradua-search-staff (searchEmit)="onSearch($event)" (backEmit)="onBack()"></cradua-search-staff>

    <div [ngSwitch]="task">
      <ng-template ngSwitchCase="list">
        <cradua-staff-list (viewEmit)="onViewStaff($event)" [staffs]="staff$ | async"></cradua-staff-list>
      </ng-template>

      <ng-template ngSwitchCase="assign">
        <cradua-staff
          [staffs]="selectedStaff"
          [url]="url"
          [certificatePermission]="certificatePermission$ | async"
          [idcardPermission]="idcardPermission$ | async"
          [isLoadingStaff]="isLoadingStaff$ | async"
          [isLoadingCertificatePermission]="isLoadingCertificatePermission$ | async"
          [isLoadingIdCardPermission]="isLoadingIdCardPermission$ | async"
          (saveEmit)="onAssignPermission($event)"
        ></cradua-staff>
      </ng-template>
    </div>
  </div>
</div>
<cradua-list-spinner *ngIf="isLoadingStaff$ | async"> </cradua-list-spinner>
