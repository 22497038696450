import { Injectable } from '@angular/core'
import { createEffect, Actions, ofType } from '@ngrx/effects'
import { map, catchError, concatMap, mergeMap } from 'rxjs/operators'
import { of, Observable } from 'rxjs'
import * as GatewayActions from './gateway.actions'
import { GatewayService } from '../../services/gateway.service'
import { Action } from '@ngrx/store'
import { NotificationService } from '@cradua/notification'
import { Router } from '@angular/router'
import Swal from 'sweetalert2'

@Injectable()
export class GatewayEffects {
  mainActionType: string

  public SetActionType(type: string) {
    this.mainActionType = type
  }

  protected loadAllData = (actions, type) => {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.loadGateway(type)),
      mergeMap((action) =>
        this.gatewayService.getAll(type).pipe(
          map((data) => {
            return actions.loadGatewaySuccess(type)({ returnedData: data })
          }),
          catchError((error) => of(actions.loadGatewayFailure(type)({ error }))),
        ),
      ),
    )
    return fn
  }

  protected genericFilter = (actions, type) => {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.genericFilterGateway(type)),
      mergeMap((action) =>
        this.gatewayService.genericFilter(type, action.entryType, action.searchField).pipe(
          map((data) => {
            return actions.genericFilterGatewaySuccess(type)({ returnedData: data })
          }),
          catchError((error) => of(actions.genericFilterGatewayFailure(type)({ error }))),
        ),
      ),
    )
    return fn
  }

  protected getStudent = (actions, type) => {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.getStudentGateway(type)),
      mergeMap((action) =>
        this.gatewayService.getStudent(type, action.data).pipe(
          map((data) => {
            let students = []
            if (data && data?.totalCount) {
              students = data?.students
              localStorage.removeItem('totalCount')
              localStorage.setItem('totalCount', JSON.stringify(data?.totalCount[0]))
            } else {
              students = data
              localStorage.removeItem('totalCount')
            }
            return actions.getStudentGatewaySuccess(type)({ returnedData: students })
          }),
          catchError((error) => {
            this.notification.error(
              'An error occurred, please if you logged in a while ago try to login again or check your internet connection!',
            )
            return of(actions.getStudentGatewayFailure(type)({ error }))
          }),
        ),
      ),
    )
    return fn
  }
  protected loadIdCardPermission = (actions, type) => {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.loadStaffPermissionGateway(type)),
      mergeMap((action) =>
        this.gatewayService.loadStaffIdCardPermission(type, action.data).pipe(
          map((data) => {
            return actions.loadStaffPermissionGatewaySuccess(type)({ returnedData: data })
          }),
          catchError((error) => {
            this.notification.error(
              'An error occurred, please if you logged in a while ago try to login again or check your internet connection!',
            )
            return of(actions.loadStaffPermissionGatewayFailure(type)({ error }))
          }),
        ),
      ),
    )
    return fn
  }

  protected addStudent = (actions, type) => {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.addStudentGateway(type)),
      mergeMap((action) =>
        this.gatewayService.addStudent(type, action.data).pipe(
          map((data) => {
            if (data?.student?.length && data?.type === 'exist') {
              this.showErrorMessage(data)
              data = []
              this.router.navigateByUrl('/dashboard')
            } else if (data?.student?.length && data?.type === 'not found') {
              this.showErrorMessage(data)
              data = []
              this.router.navigateByUrl('/dashboard')
            }

            return actions.addStudentGatewaySuccess(type)({ returnedData: data })
          }),
          catchError((error) => {
            this.notification.error(
              'The process is taking too long to upload your records, please confirm your network strength.',
            )
            // this.notification.error('Please verify for duplicate record or wrong MatNo before uploading');
            this.router.navigateByUrl('/dashboard')
            return of(actions.addStudentGatewayFailure(type)({ error }))
          }),
        ),
      ),
    )
    return fn
  }

  showErrorMessage(data) {
    Swal.fire({
      title: `<strong>${data?.message}</strong>`,
      icon: 'info',
      html: `
        ${data?.student
          .map(
            (error, index) => `
        <p class="error_info" style="font-size:.85vw; text-align:left;">
        <span class="mat" style="margin-right:2rem;">${index + 1}</span>
        <span class="name" style="margin-right:2rem;">${error?.MatNo}</span>
        <span class="mat" >${error?.StudentName}</span>

        </p>
        `,
          )
          .join('')}
        `,
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: false,
    })
  }

  protected updateStudent = (actions, type) => {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.updateStudentGateway(type)),
      mergeMap((action) =>
        this.gatewayService.updateStudent(type, action.data).pipe(
          map((data) => {
            if (data[0]?.Status === 'Deleted') {
              this.notification.error('Deleted Successfully!')
              this.router.navigateByUrl('/dashboard')
            }

            return actions.updateStudentGatewaySuccess(type)({ returnedData: data })
          }),
          catchError((error) => {
            this.notification.error('An error occurred, please try again!')
            return of(actions.updateStudentGatewayFailure(type)({ error }))
          }),
        ),
      ),
    )
    return fn
  }

  protected addCertificatePermission = (actions, type) => {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.addStaffPermissionGateway(type)),
      mergeMap((action) =>
        this.gatewayService.addStaffCertificatePermission(type, action.data).pipe(
          map((data) => {
            return actions.addStaffPermissionGatewaySuccess(type)({ returnedData: data })
          }),
          catchError((error) => {
            this.notification.error('An error occurred, please try again!')
            return of(actions.addStaffPermissionGatewayFailure(type)({ error }))
          }),
        ),
      ),
    )
    return fn
  }

  protected loadCertificatePermission = (actions, type) => {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.loadStaffPermissionGateway(type)),
      mergeMap((action) =>
        this.gatewayService.loadStaffCertificatePermission(type, action.data).pipe(
          map((data) => {
            return actions.loadStaffPermissionGatewaySuccess(type)({ returnedData: data })
          }),
          catchError((error) => {
            this.notification.error(
              'An error occurred, please if you logged in a while ago try to login again or check your internet connection!',
            )
            return of(actions.loadStaffPermissionGatewayFailure(type)({ error }))
          }),
        ),
      ),
    )
    return fn
  }

  protected add = (actions, type) => {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.addGateway(type)),
      concatMap(({ dataToAdd }) =>
        this.gatewayService.createData(type, dataToAdd).pipe(
          map((data) => actions.addGatewaySuccess(type)({ returnedData: data })),
          catchError((error) => of(actions.addGatewayFailure(type)({ error: error.error.message }))),
        ),
      ),
    )
    return fn
  }

  protected update = (actions, type) => {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.updateGateway(type)),
      concatMap(({ dataToAdd, deletedData }) =>
        this.gatewayService.updateData(type, dataToAdd, deletedData).pipe(
          map((data) => actions.updateGatewaySuccess(type)({ returnedData: data })),
          catchError((error) => of(actions.updateGatewayFailure(type)({ error: error.error.message }))),
        ),
      ),
    )
    return fn
  }
  protected getStudentinfo = (actions, type) => {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.getStudentGateway(type)),
      mergeMap((action) =>
        this.gatewayService.getStudentInfo(type, action.data).pipe(
          map((data) => {
            let students = []
            if (data && data?.totalCount) {
              students = data?.students
              localStorage.removeItem('totalCount')
              localStorage.setItem('totalCount', JSON.stringify(data?.totalCount[0]))
            } else {
              students = data
              localStorage.removeItem('totalCount')
            }
            return actions.getStudentGatewaySuccess(type)({ returnedData: students })
          }),
          catchError((error) => {
            this.notification.error(
              'An error occurred, please if you logged in a while ago try to login again or check your internet connection!',
            )
            return of(actions.getStudentGatewayFailure(type)({ error }))
          }),
        ),
      ),
    )
    return fn
  }

  protected remove = (actions, type) => {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.removeGateway(type)),
      concatMap(({ ids }) =>
        this.gatewayService.deleteData(type, ids).pipe(
          map((data) => actions.removeGatewaySuccess(type)({ ids: data })),
          catchError((error) => of(actions.updateGatewayFailure(type)({ error: error.error.message }))),
        ),
      ),
    )
    return fn
  }
  protected addIdCardPermission = (actions, type) => {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.addStaffPermissionGateway(type)),
      mergeMap((action) =>
        this.gatewayService.addStaffIdCardPermission(type, action.data).pipe(
          map((data) => {
            return actions.addStaffPermissionGatewaySuccess(type)({ returnedData: data })
          }),
          catchError((error) => {
            this.notification.error('An error occurred, please try again!')
            return of(actions.addStaffPermissionGatewayFailure(type)({ error }))
          }),
        ),
      ),
    )
    return fn
  }
  protected loadClearState(actions, type) {
    const fn: Observable<Action> = this.actions$.pipe(
      ofType(actions.loadGatewayClearState(type)),
      mergeMap((action) => of(actions.loadGatewayClearStateSuccess(type)({ returnedData: [] }))),
    )
    return fn
  }

  loadGatewayBank = createEffect(() => this.loadAllData(GatewayActions, 'Bank'))
  addGatewayBank$ = createEffect(() => this.add(GatewayActions, 'Bank'))
  updateGatewayBank$ = createEffect(() => this.update(GatewayActions, 'Bank'))
  deleteGatewayBank$ = createEffect(() => this.remove(GatewayActions, 'Bank'))

  loadGatewayContinent = createEffect(() => this.loadAllData(GatewayActions, 'Continent'))
  addGatewayContinent$ = createEffect(() => this.add(GatewayActions, 'Continent'))
  updateGatewayContinent$ = createEffect(() => this.update(GatewayActions, 'Continent'))
  deleteGatewayContinent$ = createEffect(() => this.remove(GatewayActions, 'Continent'))

  loadGatewayCountry = createEffect(() => this.loadAllData(GatewayActions, 'Country'))
  addGatewayCountry$ = createEffect(() => this.add(GatewayActions, 'Country'))
  updateGatewayCountry$ = createEffect(() => this.update(GatewayActions, 'Country'))
  deleteGatewayCountry$ = createEffect(() => this.remove(GatewayActions, 'Country'))

  loadGatewayState = createEffect(() => this.loadAllData(GatewayActions, 'State'))
  addGatewayState$ = createEffect(() => this.add(GatewayActions, 'State'))
  updateGatewayState$ = createEffect(() => this.update(GatewayActions, 'State'))
  deleteGatewayState$ = createEffect(() => this.remove(GatewayActions, 'State'))

  loadGatewayLga = createEffect(() => this.loadAllData(GatewayActions, 'Lga'))
  addGatewayLga$ = createEffect(() => this.add(GatewayActions, 'Lga'))
  updateGatewayLga$ = createEffect(() => this.update(GatewayActions, 'Lga'))
  deleteGatewayLga$ = createEffect(() => this.remove(GatewayActions, 'Lga'))

  loadGatewayCommunity = createEffect(() => this.loadAllData(GatewayActions, 'Communities'))
  addGatewayCommunity$ = createEffect(() => this.add(GatewayActions, 'Communities'))
  updateGatewayCommunity$ = createEffect(() => this.update(GatewayActions, 'Communities'))
  deleteGatewayCommunity$ = createEffect(() => this.remove(GatewayActions, 'Communities'))

  loadGatewayCurrency$ = createEffect(() => this.loadAllData(GatewayActions, 'Currency'))
  addGatewayCurrency$ = createEffect(() => this.add(GatewayActions, 'Currency'))
  updateGatewayCurrency$ = createEffect(() => this.update(GatewayActions, 'Currency'))
  deleteGatewayCurrency$ = createEffect(() => this.remove(GatewayActions, 'Currency'))

  loadGatewayPostGraduateDegree$ = createEffect(() => this.loadAllData(GatewayActions, 'PostGraduateDegree'))
  addGatewayPostGraduateDegree$ = createEffect(() => this.add(GatewayActions, 'PostGraduateDegree'))
  updateGatewayPostGraduateDegree$ = createEffect(() => this.update(GatewayActions, 'PostGraduateDegree'))
  deleteGatewayPostGraduateDegree$ = createEffect(() => this.remove(GatewayActions, 'PostGraduateDegree'))

  loadGatewayCollege$ = createEffect(() => this.loadAllData(GatewayActions, 'College'))
  addGatewayCollege$ = createEffect(() => this.add(GatewayActions, 'College'))
  updateGatewayCollege$ = createEffect(() => this.update(GatewayActions, 'College'))
  deleteGatewayCollege$ = createEffect(() => this.remove(GatewayActions, 'College'))

  loadGatewaySession$ = createEffect(() => this.loadAllData(GatewayActions, 'Session'))
  addGatewaySession$ = createEffect(() => this.add(GatewayActions, 'Session'))
  updateGatewaySession$ = createEffect(() => this.update(GatewayActions, 'Session'))
  deleteGatewaySession$ = createEffect(() => this.remove(GatewayActions, 'Session'))

  loadGatewayUserStaffDetail$ = createEffect(() => this.loadAllData(GatewayActions, 'UserStaffDetail'))
  addGatewayUserStaffDetail$ = createEffect(() => this.add(GatewayActions, 'UserStaffDetail'))
  updateGatewayUserStaffDetail$ = createEffect(() => this.update(GatewayActions, 'UserStaffDetail'))
  deleteGatewayUserStaffDetail$ = createEffect(() => this.remove(GatewayActions, 'UserStaffDetail'))

  loadGatewayIdentityUser$ = createEffect(() => this.loadAllData(GatewayActions, 'IdentityUser'))

  searchGatewayIdentityUser$ = createEffect(() => this.genericFilter(GatewayActions, 'IdentityUser'))

  loadGatewayPLActivityActionPerson$ = createEffect(() => this.loadAllData(GatewayActions, 'PLActivityActionPerson'))

  loadGatewaySex$ = createEffect(() => this.loadAllData(GatewayActions, 'Sex'))
  addGatewaySex$ = createEffect(() => this.add(GatewayActions, 'Sex'))
  updateGatewaySex$ = createEffect(() => this.update(GatewayActions, 'Sex'))
  deleteGatewaySex$ = createEffect(() => this.remove(GatewayActions, 'Sex'))

  loadGatewayFaculty$ = createEffect(() => this.loadAllData(GatewayActions, 'Faculty'))
  addGatewayFaculty$ = createEffect(() => this.add(GatewayActions, 'Faculty'))
  updateGatewayFaculty$ = createEffect(() => this.update(GatewayActions, 'Faculty'))
  deleteGatewayFaculty$ = createEffect(() => this.remove(GatewayActions, 'Faculty'))

  loadGatewayDepartment$ = createEffect(() => this.loadAllData(GatewayActions, 'Department'))
  addGatewayDepartment$ = createEffect(() => this.add(GatewayActions, 'Department'))
  updateGatewayDepartment$ = createEffect(() => this.update(GatewayActions, 'Department'))
  deleteGatewayDepartment$ = createEffect(() => this.remove(GatewayActions, 'Department'))

  loadGatewayGraduationCertificate$ = createEffect(() => this.loadAllData(GatewayActions, 'GraduationCertificate'))
  addGatewayGraduationCertificate$ = createEffect(() => this.add(GatewayActions, 'GraduationCertificate'))
  updateGatewayGraduationCertificate$ = createEffect(() => this.update(GatewayActions, 'GraduationCertificate'))
  getStudentGatewayGraduationCertificate$ = createEffect(() => this.getStudent(GatewayActions, 'GraduationCertificate'))
  addStudentGatewayGraduationCertificate$ = createEffect(() => this.addStudent(GatewayActions, 'GraduationCertificate'))
  updateStudentGatewayGraduationCertificate$ = createEffect(() =>
    this.updateStudent(GatewayActions, 'GraduationCertificate'),
  )
  deleteGatewayGraduationCertificate$ = createEffect(() => this.remove(GatewayActions, 'GraduationCertificate'))
  clearGraduationCertificate$ = createEffect(() => this.loadClearState(GatewayActions, 'GraduationCertificate'))

  addCertificatePermission$ = createEffect(() => this.addCertificatePermission(GatewayActions, 'CertificatePermission'))
  loadCertificatePermission$ = createEffect(() =>
    this.loadCertificatePermission(GatewayActions, 'CertificatePermission'),
  )
  loadGatewayCardExpiration$ = createEffect(() => this.loadAllData(GatewayActions, 'CardExpiration'))
  addGatewayCardExpiration$ = createEffect(() => this.add(GatewayActions, 'CardExpiration'))
  updateGatewayCardExpiration$ = createEffect(() => this.update(GatewayActions, 'CardExpiration'))
  deleteGatewayCardExpiration$ = createEffect(() => this.remove(GatewayActions, 'CardExpiration'))

  loadGatewayCertificateSignature$ = createEffect(() => this.loadAllData(GatewayActions, 'CertificateSignature'))
  deleteGatewayCertificateSignature$ = createEffect(() => this.remove(GatewayActions, 'CertificateSignature'))
  addIdCardPermission$ = createEffect(() => this.addIdCardPermission(GatewayActions, 'IdCardPermission'))
  loadIdCardPermission$ = createEffect(() => this.loadIdCardPermission(GatewayActions, 'IdCardPermission'))

  loadGatewayStudentinfo$ = createEffect(() => this.loadAllData(GatewayActions, 'StudentInfo'))
  getStudentGatewayStudentinfo$ = createEffect(() => this.getStudentinfo(GatewayActions, 'StudentInfo'))

  constructor(
    private actions$: Actions,
    private router: Router,
    private gatewayService: GatewayService,
    private notification: NotificationService,
  ) {}
}
