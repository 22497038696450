import { Component } from '@angular/core'
import { AuthService } from 'libs/auth-front/src/lib/service/auth.service'
import { Observable } from 'rxjs'

// function getFieldName<T,K extends keyof T>(obj: T, keys: K[]): T[K][] {
//   return keys.map(key => obj[key])
// }

@Component({
  selector: 'cradua-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  title = 'cradua'
  isLoggedIn$: Observable<boolean>;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.isLoggedIn$ = this.authService.isLoggedIn$


  }

}
