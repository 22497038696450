<div class="certificate_signin">
  <div class="image_holder"><img loading="lazy" src="assets/images/right.png" alt="" /></div>
  <div class="certificate_signin-form">
    <form [formGroup]="loginForm" class="cert_signin-form">
      <h2 class="title">Sign in</h2>
      <div class="alert alert-danger" *ngIf="getAuthError">Invalid Username or password</div>
      <div class="cert_form-input">
        <label for="">Username</label>
        <input formControlName="username" type="text" class="input" />
        <i class="ti-user"></i>
      </div>
      <div class="cert_form-input">
        <label for="">Password</label>
        <input type="password" formControlName="password" class="input" />
        <i class="ti-eye"></i>
      </div>
      <button class="signin_btn"  [ngClass]="isLoadingLogin ? 'disable' : '' || loginForm.invalid ? 'disable_login':''" type="submit" (click)="onSubmit(loginForm.value)">

        <ng-container *ngIf="!isLoadingLogin">Sign in </ng-container>
        <span *ngIf="isLoadingLogin" class="spinner-border text-light"></span>

        </button>
    </form>
  </div>
</div>
