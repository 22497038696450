import { BrowserModule } from '@angular/platform-browser'
import { ErrorHandler, NgModule } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component'
import { StoreModule } from '@ngrx/store'
import { reducers } from './reducer'
import { MaterialModule } from '@cradua/material'
// import { AuthFrontModule, AuthGuard } from '@cradua/auth-front'
import { EffectsModule } from '@ngrx/effects'
import { GatewayFrontModule } from '@cradua/gateway-front'
import { AppRoutingModule } from './app-routing.module'
import { AuthGuard } from 'libs/auth-front/src/lib/auth.guard'
import { AuthFacade } from '@cradua/auth-front'
import { DashboardNavModule } from '@cradua/dashboard-nav'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from 'libs/auth-front/src/lib/token.interceptor';
import { HttpErrorInterceptor } from 'libs/auth-front/src/lib/http-error.interceptor';
import { AppErrorHandler } from 'libs/auth-front/src/lib/error-handler';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    GatewayFrontModule,

    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictActionSerializability: false,
        strictStateSerializability: false,
      },
    }),
    AppRoutingModule,
    DashboardNavModule,
    // StoreDevtoolsModule.instrument({
    //   maxAge: 25, // Retains last 25 states
    //   logOnly: environment.production, // Restrict extension to log-only mode
    // }),
    EffectsModule.forRoot([]),
  ],
  providers: [
    AuthGuard,
    AuthFacade,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
