import { Injectable, ErrorHandler } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "@cradua/notification";

/** Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(private notificationsService: NotificationService) {
    super();
  }

  handleError(error: Error | HttpErrorResponse) {
    let displayMessage = "An error occurred: ";
    // console.log('this is error ', error);
    // if (!environment.production) {
    // 	displayMessage += error.message;
    // }

    // this.notificationsService.error(displayMessage);

    super.handleError(error);
  }
}
