import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EffectsModule } from '@ngrx/effects'
import { GatewayEffects } from './states/+gateway/gateway.effects'
import { GatewayFacade } from './states/+gateway/gateway.facades'
import { StoreModule } from '@ngrx/store'

import * as gatewayreducer from './states/+gateway/gateway.reducer'
import { HttpClientModule } from '@angular/common/http'

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,

    StoreModule.forFeature(gatewayreducer.Gateway_FEATURE_KEY_Bank, gatewayreducer.bankreducer),
    StoreModule.forFeature(gatewayreducer.Gateway_FEATURE_KEY_Continent, gatewayreducer.continentreducer),
    StoreModule.forFeature(gatewayreducer.Gateway_FEATURE_KEY_Country, gatewayreducer.countryreducer),

    StoreModule.forFeature(gatewayreducer.Gateway_FEATURE_KEY_State, gatewayreducer.statereducer),

    StoreModule.forFeature(gatewayreducer.Gateway_FEATURE_KEY_Lga, gatewayreducer.lgareducer),

    StoreModule.forFeature(gatewayreducer.Gateway_FEATURE_KEY_Community, gatewayreducer.communityreducer),

    StoreModule.forFeature(gatewayreducer.Gateway_FEATURE_KEY_IdentityUser, gatewayreducer.identityUserreducer),

    StoreModule.forFeature(gatewayreducer.Gateway_FEATURE_KEY_UserStaffDetail, gatewayreducer.userStaffDetailreducer),

    StoreModule.forFeature(gatewayreducer.Gateway_FEATURE_KEY_Sex, gatewayreducer.sexreducer),
    StoreModule.forFeature(gatewayreducer.Gateway_FEATURE_KEY_Faculty, gatewayreducer.facultyreducer),
    StoreModule.forFeature(gatewayreducer.Gateway_FEATURE_KEY_Department, gatewayreducer.departmentreducer),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_GraduationCertificate,
      gatewayreducer.graduationcertificatereducer,
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_CertificatePermission,
      gatewayreducer.certificatePermission,
    ),
    StoreModule.forFeature(
      gatewayreducer.Gateway_FEATURE_KEY_CertificateSignature,
      gatewayreducer.certificatesignaturereducer,
    ),
    StoreModule.forFeature(gatewayreducer.Gateway_FEATURE_KEY_PostGraduateDegree, gatewayreducer.postGraduateDegree),
    StoreModule.forFeature(gatewayreducer.Gateway_FEATURE_KEY_College, gatewayreducer.college),
    StoreModule.forFeature(gatewayreducer.Gateway_FEATURE_KEY_Session, gatewayreducer.session),

    StoreModule.forFeature(gatewayreducer.Gateway_FEATURE_KEY_StudentInfo, gatewayreducer.studentinforeducer),
    StoreModule.forFeature(gatewayreducer.Gateway_FEATURE_KEY_CardExpiration, gatewayreducer.cardexpirationreducer),
    StoreModule.forFeature(gatewayreducer.Gateway_FEATURE_KEY_IdCardPermissionn, gatewayreducer.idcardPermission),

    EffectsModule.forFeature([GatewayEffects]),
  ],
  providers: [GatewayFacade],
})
export class GatewayFrontModule {}
